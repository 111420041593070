<template>
    <v-text-field
      :label="label" name="min" type="text" :value="value" :disabled="disabled"
      v-bind:placeholder="$t('placeholders.date')"
      @input="input"
      :rules="rules"
    >
      <template v-slot:append>
        <v-dialog v-model="dialog" width="unset">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              icon
              v-on="on"
              v-bind="attrs"
              color="primary"
              :disabled="disabled"
              @click="dialog = false"
            >
              <v-icon>mdi-calendar</v-icon>
            </v-btn>
          </template>

          <v-date-picker
            :value="pickerDate"
            @click:date="selectDate($event)"
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dialog = false"
            >
              {{ $t('common.action.close') }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </template>
    </v-text-field>
</template>

<script>
export default {
  name: 'date-field',
  props: {
    value: { required: true },
    rules: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    label: { type: String, default: 'Date' },
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    date() {
      if (!this.value) return null;
      if (!this.isValid(this.value)) return null;
      return Date.parse(this.swapDate(this.value, '.', '-'));
    },
    pickerDate() {
      const { date } = this;
      if (!date || Number.isNaN(date)) return null;
      return this.swapDate(this.value, '.', '-');
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
    selectDate(date) {
      this.input(this.swapDate(date, '-', '.'));
    },
    isValid(v) {
      return /^\d\d\.\d\d\.\d\d\d\d$/.test(v);
    },
    swapDate(date, separatorA, separatorB) {
      return date.split(separatorA).reverse().join(separatorB);
    },
  },
};
</script>
